<template>
  <div>
    <a-form layout="inline" :model="search">
      <a-form-item label="创建时间">
        <a-date-picker show-time placeholder="选择时间" format="YYYY-MM-DD" @change="timeChange" />
      </a-form-item>
      <a-form-item label="渠道分类">
        <a-cascader allowClear :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" @change="setChannel" placeholder="选择渠道" />
      </a-form-item>
      <a-form-item label="操作人">
        <a-input v-model:value="search.operator" placeholder="请输入操作人" />
      </a-form-item>
      <a-form-item label="">
        <a-button style="background:#344563;color:#fff" @click="searchData" :loading="loading">搜索</a-button>
      </a-form-item>
    </a-form>
    <div style="display:flex;justify-content:flex-start">
      <a-button style="background:#344563;color:#fff;margin:15px 0;" @click="addListStatus = true" :loading="loading">设置消费金额</a-button>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="dataSource" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{record}">
        <div style="display:flex;justify-content: space-between;padding:0 10px;">
          <span>{{record.operator}}</span>
          <span class="edit-btn"><a @click="edit(record)">修改</a></span>
        </div>
      </template>
    </a-table>
    <a-modal v-model:visible="editStatus" title="修改消费" @ok="saveEdit">
      <a-form-item label="消费时间">
        <span style="color:#BCC1CA">{{editObj.consumptionDate}}</span>
      </a-form-item>
      <a-form-item label="搜索推广">
        <a-input v-model:value="editObj.expenditure" suffix="元" />
      </a-form-item>
    </a-modal>

    <a-modal v-model:visible="addListStatus" title="设置今日消费" @ok="addList" @cancel="channelChild = []" :destroyOnClose="true">
      <p style="display:flex;justify-content: space-between;">
        <a-form-item label="渠道分类">
          <a-select allowClear :options="$store.state.enumAll.ChannelTypeEnum" :changeOnSelect="true" @change="addListChange" placeholder="选择渠道" />
        </a-form-item>
        <a-form-item>
          <template #label>
            <div>
              消费日期<span style="color:#f00">*</span>
            </div>
          </template>
          <a-date-picker v-model:value="addData.consumptionDate" @change="changeTime" />
        </a-form-item>
      </p>
      <a-form-item v-for="item in channelChild" :key="item.value" :label="item.label">
        <a-input v-model:value="item.expenditure" suffix="元" />
      </a-form-item>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getPromotionCostList, addCost, editData } from '@/api/crmManagement/drainageClue'
import { message } from 'ant-design-vue'

export default {
  props: {
    channels: Array
  },
  setup (props) {
    const state = reactive({
      addListStatus: false,
      editStatus: false,
      loading: false,
      channelChild: [],
      channelList: [],
      dataSource: [],
      addData: { marketPromotionCostList: [] },
      editObj: {
        createTime: '',
        expenditure: '',
        channelId: '',
        id: ''
      },
      pagination: {
        current: 1,
        pageSizeOptions: ['3', '5', '10'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      search: {
        createTime: '',
        channelId: '',
        operator: ''
      },
      columns: [
        {
          title: '消费时间',
          dataIndex: 'consumptionDate'
        },
        {
          title: '渠道分类',
          dataIndex: 'channelName'
        },
        {
          title: '渠道名称',
          dataIndex: 'subChannelName'
        },
        {
          title: '推广消费(元)',
          dataIndex: 'expenditure'
        },
        {
          title: '操作人',
          dataIndex: 'operator',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const setChannel = (e, v) => {
      if (e.length > 1) {
        state.search.channelId = e[0]
        state.search.channelSubId = e[1]
      } else {
        state.search.channelId = e[0]
        state.search.channelSubId = ''
      }
    }
    const loadData = () => {
      state.loading = true
      getPromotionCostList({
        ...state.search,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => { console.log(err) })
        .finally(() => {
          state.loading = false
        })
    }
    onMounted(() => {
      if (props.channels) state.channelList = props.channels
      loadData()
    })
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const addListChange = (e, v) => {
      if (e.lengch > 1) state.search.channelId = e[1]
      v.children.forEach(item => {
        item.expenditure = ''
      })
      state.channelChild = v.children
    }
    const edit = record => {
      state.editStatus = true
      state.editObj = record
    }
    const saveEdit = () => {
      delete state.editObj.createTime
      delete state.editObj.channelName
      delete state.editObj.operator
      delete state.editObj.subChannelName
      editData({ ...state.editObj })
        .then(res => {
          if (res.code === 10000) {
            message.success('修改' + res.msg)
            loadData()
            state.editStatus = false
          }
        }).catch(err => { console.log(err) })
    }
    const addList = () => {
      if (state.addData.consumptionDate !== '' && state.addData.consumptionDate !== null && state.addData.consumptionDate !== undefined) {
        state.addData.marketPromotionCostList = []
        state.channelChild.forEach((item, index) => {
          state.addData.marketPromotionCostList.push({
            channelId: item.value,
            expenditure: item.expenditure
          })
        })
        addCost(state.addData)
          .then(res => {
            if (res.code === 10000) {
              message.success('设置' + res.msg)
              state.addListStatus = false
              state.addData = { marketPromotionCostList: [] }
              state.channelChild = []
            }
          }).catch(err => { console.log(err) })
      } else message.error('请先选择日期时间')
    }
    const changeTime = (e, v) => {
      state.addData.consumptionDate = v
    }
    const timeChange = (e, v) => {
      state.search.createTime = v
    }
    const sourceChange = (e, v) => {
      state.search.source.label = v.key
      state.search.source.value = v.value
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }

    return {
      ...toRefs(state),
      handleTableChange,
      addListChange,
      sourceChange,
      searchData,
      changeTime,
      timeChange,
      setChannel,
      saveEdit,
      addList,
      edit
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
