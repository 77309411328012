import { postBodyRequest,getRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/market/crm/public/page', params)
}
export function call (params) {
  return postBodyRequest('/market/crm/public/receive/call', params)
}
export function receive (customerId) {
  return postBodyRequest(`/market/crm/public/receive/${customerId}`)
}
export function add (params) {
  return postBodyRequest('/market/crm/public/add', params)
}
export function addPublic (params) {
  return postBodyRequest('/market/crm/public/add/public', params)
}

export function allocate (params) {
  return postBodyRequest('/market/crm/public/compulsoryAllocate', params)
}
export function receiveOneClick (params) {
  return postBodyRequest('/market/crm/public/oneClick', params)
}
export function uploadData (fileId, params) {
  return getRequest(`/market/crm/public/batch-add/${fileId}`, params)
}
