<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <!-- 搜索栏 -->
    <template #search>
      <a-form layout="inline" :model="search" style="justify-content: flex-end;">
        <a-form-item label="">
          <a-range-picker v-model:value="search.time" @change="timeChange" format="YYYY-MM-DD"
            :placeholder="['起始时间', '截止时间']" style="width:100%" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader v-model:value="searchForm.channel" :options="$store.state.enumAll.ChannelTypeEnum"
            :show-search="{ filter }" :changeOnSelect="true" @change="setChannel" placeholder="渠道来源" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.phone" @blur="phoneChange" placeholder="电话号码" />
        </a-form-item>
        <a-form-item label="">
          <!--          <a-select  v-model:value="searchForm.customerClass"  allowClear @change="customerClassChange" :options="$store.state.enumAll.CustomerClassEnum" :changeOnSelect="true" placeholder="客户位置" />-->
          <a-select v-model:value="searchForm.customerClass" allowClear :options="$store.state.enumAll.CustomerClassEnum"
            :changeOnSelect="true" placeholder="客户位置" />
        </a-form-item>
        <a-form-item label="">
          <a-select style="width:120px" v-model:value="searchForm.clueType" allowClear @change="clueChange"
            :options="$store.state.enumAll.ClueTypeEnum" :changeOnSelect="true" placeholder="线索分类" />
        </a-form-item>
        <a-form-item label="" style="flex:1">
          <a-select v-model:value="searchForm.isEffective" allowClear @change="effectiveChange" placeholder="是否有效"
            :options="$store.state.enumAll.effectiveEnum" :changeOnSelect="true" style="width:130px" />
        </a-form-item>
        <a-form-item label="" style="flex:1">
          <a-select v-model:value="searchForm.isRepetitive" :options="$store.state.enumAll.BooleanFlagEnum"
            placeholder="是否重复" style="width:130px" />
        </a-form-item>
        <a-form-item label="">
          <span @click="searchSeniorShow = true" class="colo_blue cursor-pointer" style="margin-right:10px">高级搜索</span>
          <!-- <span>已选择<span class="colo_blue cursor-pointer"> {{quantity}} </span>项</span> -->
        </a-form-item>
        <a-form-item label="">
          <a-button style="background:#344563;color:#fff" @click="searchData" :loading="loading">搜索</a-button>
          <a-button style="margin-left:10px;background:#F0F2F5" @click="reset">重置</a-button>
          <a-button style="background:#344563;color:#fff" @click="exportData" :loading="loading">导出</a-button>
        </a-form-item>
      </a-form>
    </template>
    <!-- 文字文案 -->
    <template #totalbar>
      <ExclamationCircleTwoTone twoToneColor="#427BB1" /> 当前总线索 <span
        style="color:#1890ff;font-weight: bold;">{{ pagination.total }}</span> 条
    </template>
    <!-- 按钮展示 -->
    <template #toolbarLeft>
      <a-button @click="popularize = true" class="tool-color-344563" style="margin-right:8px">
        <template #icon>
          <TransactionOutlined class="tool-icon" />
        </template>
        推广消费
      </a-button>
      <a-button @click="searchAddShow = true" class="tool-color-344563">
        <PlusCircleOutlined class="tool-icon" />
        新增线索
      </a-button>
    </template>
    <!-- 高级搜索 -->
    <a-modal v-model:visible="searchSeniorShow" width="800px" title="高级搜索设置" @ok="onSearchSenior">
      <div class="searchSenior-modal">
        <div class="searchSenior-modal-line">
          <div class="searchSenior-modal-line-label">
            领取时间：
          </div>
          <div class="searchSenior-modal-line-value">
            <a-range-picker :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" :placeholder="['起始时间', '截止时间']"
              @change="receiveChange" style="width:50%" />
          </div>
        </div>
        <div class="searchSenior-modal-line">
          <div class="searchSenior-modal-line-label">
            客户类型：
          </div>
          <div class="searchSenior-modal-line-value">
            <a-radio-group v-model:value="searchForm.customerType" button-style="solid">
              <a-space>
                <a-radio-button :value="item.value" v-for="item in $store.state.enumAll.CustomerTypeEnum"
                  :key="item.value">{{ item.label }}</a-radio-button>
              </a-space>
            </a-radio-group>
          </div>
        </div>
        <div class="searchSenior-modal-line">
          <div class="searchSenior-modal-line-label">
            线索类型：
          </div>
          <div class="searchSenior-modal-line-value">
            <a-radio-group v-model:value="searchForm.clueType" button-style="solid">
              <a-space>
                <a-radio-button :value="item.value" v-for="item in $store.state.enumAll.ClueTypeEnum"
                  :key="item.value">{{ item.label }}</a-radio-button>
              </a-space>
            </a-radio-group>
          </div>
        </div>
        <div class="searchSenior-modal-line">
          <div class="searchSenior-modal-line-label">
            结算类型：
          </div>
          <div class="searchSenior-modal-line-value">
            <a-radio-group v-model:value="searchForm.settlementMode" button-style="solid">
              <a-space>
                <a-radio-button :value="index" v-for="(item, index) in $store.state.enumAll.CustomerSettlementEnum"
                  :key="index">{{ item.label }}</a-radio-button>
              </a-space>
            </a-radio-group>
          </div>
        </div>
        <div class="searchSenior-modal-line">
          <div class="searchSenior-modal-line-label">
            客户标签：
          </div>
          <div class="searchSenior-modal-line-value">
            <a-radio-group v-model:value="searchForm.tagId" button-style="solid">
              <a-space>
                <a-radio-button v-for="item in $store.state.enumAll.CustomerLabelType" :key="item.value"
                  :value="item.value">{{ item.label }}</a-radio-button>
              </a-space>
            </a-radio-group>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 表格主体 -->
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="pageData" bordered
      :pagination="pagination" :loading="loading" :scroll="{ x: 1900 }" @change="handleTableChange">
      <template #channel="{ record }">
        {{ record.channelName }} <span
          v-if="record.channelSubName != null && record.channelSubName != null">-{{ record.channelSubName }}</span>
      </template>
      <template #operation="{ record }">
        <span>
          {{ record.followContent }}
        </span>
        <!-- <a-divider type="vertical" /> -->
        <span>
          <a @click="remind(record)">提醒</a>
        </span>
      </template>
    </a-table>
  </HxTable>
  <!-- 新增 -->
  <a-modal v-model:visible="searchAddShow" width="30%" @ok="onAddForm">
    <template #title>
      <span class="title">新增</span>
    </template>
    <div>
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item label="客户电话" name="phoneNumber">
          <a-input v-model:value="addForm.phoneNumber" placeholder="填写电话" />
        </a-form-item>
        <a-form-item label="客户微信">
          <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
        </a-form-item>
        <a-form-item label="客户报价">
          <a-input v-model:value="addForm.totalPrice" placeholder="填写客户报价" />
        </a-form-item>
        <a-form-item label="起始地点">
          <div style="display:flex;justify-content: space-around;align-items:center">
            <a-cascader style="width:45%" v-model:value="start" :show-search="{}" :options="$store.state.app.cityDict"
              :changeOnSelect="true" @change="setStartAddress" placeholder="选择起始地" />
            <div>——</div>
            <a-cascader style="width:45%" v-model:value="end" :show-search="{}" :options="$store.state.app.cityDict"
              :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" />
          </div>
        </a-form-item>
        <a-form-item label="渠道来源" name="channelSubIds">
          <a-cascader v-model:value="addForm.channelSubIds" :options="$store.state.enumAll.ChannelTypeEnum"
            :changeOnSelect="true" placeholder="选择渠道" />
        </a-form-item>
        <a-form-item>
          <template #label>
            <div>
              <span>客户标签 </span>
              <a style="font-size:12px" @click="addForm.tagId = ''"> 重置选择</a>
            </div>
          </template>
          <div>
            <a-radio-group v-model:value="addForm.tagId" button-style="solid">
              <a-space>
                <a-radio-button v-for="item in $store.state.enumAll.CustomerType" :key="item.value"
                  :value="item.value">{{ item.label }}</a-radio-button>
              </a-space>
            </a-radio-group>
          </div>
        </a-form-item>
        <a-form-item label="备注">
          <a-input placeholder="填写备注" v-model:value="addForm.remark" />
        </a-form-item>
      </a-form>

    </div>
  </a-modal>
  <!-- 查看跟进纪录 -->
  <a-drawer title="跟进记录" width="80%" placement="right" v-model:visible="followShow">
    <div v-if="listData.length > 0">
      <a-timeline>
        <a-timeline-item class="tool_w_30" v-for="item in listData" :key="item.name">
          <template #dot>
            <p class="see-drawer-dot-p">{{ item.S }}</p>
            <p class="see-drawer-dot-p1">{{ item.M }}</p>
          </template>
          <div class="see-drawer-content">
            <p>{{ item.followName }} <a-tag v-if='item.followType != null'>{{ item.followType.label }}</a-tag>
              <a-tag>{{ item.followLabelName }}</a-tag>
            </p>
            <p class="see-drawer-content-time">{{ item.followTime }} </p>
            <p class="see-drawer-content-time">{{ item.intentionTypeName }} </p>
            <p>{{ item.content }}</p>
            <p class="see-drawer-content-time">
              <icon-font type="hy-shijian" class="tool-icon" /> 下次联系时间：{{ item.followNextTime }} {{ item.followNextContent }}
            </p>
            <div class="see-drawer-content-imgArry">
              <a-image-preview-group>
                <div class="see-drawer-content-imgArry-for" v-for="itemImg in item.fileUrls" :key="itemImg.id">
                  <a-image :src="itemImg.url" />
                </div>
              </a-image-preview-group>
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
      <a-pagination show-quick-jumper v-model:current="detailPagination.current" :show-total="detailPagination.showTotal"
        :page-size-options="detailPagination.pageSizeOptions" :total="detailPagination.total" @change="onChange" />
    </div>
    <a-empty v-else />
  </a-drawer>
  <!-- 提醒跟进记录 -->
  <a-drawer title="跟进记录" width="80%" placement="right" v-model:visible="reminShow" :destroyOnClose="true">
    <FollowAdd :customerId="customerId" :followType="9" />
  </a-drawer>
  <a-drawer title="推广消费" placement="right" :width="1000" :closable="false" v-model:visible="popularize"
    :destroyOnClose="true">
    <Popularize :channels="channelList" />
  </a-drawer>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { mobileValidate } from '@/utils/validate'
import { getChannelList } from '@/api/global'
import { contactCustomerIdPage } from '@/api/crmManagement/follow'
import { addPublic } from '@/api/crmManagement/comSea'
import { getPage, exportClue } from '@/api/crmManagement/drainageClue'
import { message } from 'ant-design-vue'
import { getTimeYMD } from '@/utils/util'
import { TransactionOutlined, ExclamationCircleTwoTone, createFromIconfontCN, PlusCircleOutlined } from '@ant-design/icons-vue'

import HxTable from '@/components/HxTable'
import Popularize from './popularize'
import FollowAdd from '../tool/customerFollow/add.vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
export default {
  components: {
    HxTable,
    IconFont,
    FollowAdd,
    Popularize,
    PlusCircleOutlined,
    TransactionOutlined,
    ExclamationCircleTwoTone
  },
  setup () {
    const formRef = ref()
    const state = reactive({
      searchSeniorShow: false,
      value: [],
      popularize: false,
      searchAddShow: false,
      reminShow: false,
      loading: false,
      followShow: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      flag5: false,
      empty: {},
      searchSpecial: {},
      BooleanFlagEnum: 0,
      quantity: 2,
      customerId: '',
      start: [],
      end: [],
      search: {
        channel: '',
        clueType: '',
        time: [getTimeYMD(), getTimeYMD()]
      },
      searchForm: {
        channel: 0,
        phone: '',
        isRepetitive: null,
        customerType: '',
        settlementMode: 0,
        clueType: 0,
        tagId: ''
      },
      mirrorSearch: {},
      mirrorSearchForm: {},
      addForm: {
        wechat: '',
        phoneNumber: '',
        startAddress: {},
        endAddress: {}
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      detailPagination: {
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: 1,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        phoneNumber: [{ required: false, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
        wechat: [{ required: false, message: '请填写微信号码', trigger: 'blur' }, { pattern: /[a-zA-Z0-9]{6,}/, message: '请填写正确的微信号码', trigger: 'blur' }],
        channelSubIds: { required: true, message: '请选择渠道来源', trigger: 'change', type: 'array' }
      },
      pageData: [],
      listData: [],
      labelList: [],
      channelList: [],
      CustomerLabelType: [],
      columns: [
        {
          title: '电话',
          dataIndex: 'phone',
          width: '6%'
        },
        {
          title: '微信号',
          dataIndex: 'wechat'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '7%'
        },
        {
          title: '领取时间',
          dataIndex: 'receiveTime',
          width: '7%'
        },
        {
          title: '起点',
          dataIndex: 'startAddress',
          width: '8%'
        },
        {
          title: '终点',
          dataIndex: 'endAddress',
          width: '8%'
        },
        {
          title: '渠道来源',
          dataIndex: 'channelName',
          width: '8%',
          slots: {
            customRender: 'channel'
          }
        },
        {
          title: '线索分类',
          dataIndex: 'clueType.label',
          width: '4%'
        },
        {
          title: '客户位置',
          dataIndex: 'customerClass.label',
          width: '5%'
        },
        {
          title: '是否有效',
          dataIndex: 'isEffective.label',
          width: '5%'
        },
        {
          title: '托运用途',
          dataIndex: 'transportPurpose'
        },
        {
          title: '返程计划',
          dataIndex: 'returnPlan.label'
        },
        {
          title: '报价',
          dataIndex: 'totalPrice'
        },
        {
          title: '标签',
          dataIndex: 'tagName'
        },
        {
          title: '首次负责人',
          dataIndex: 'firstSaleName'
        },
        {
          title: '客户负责人',
          dataIndex: 'saleName'
        },
        {
          title: '跟进记录',
          dataIndex: 'operation',
          width: 80,
          align: 'center',
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const loadData = () => {
      state.loading = true
      getPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.pageData = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      }).catch(err => { console.log(err) })
    }
    const exportData = () => {
      state.loading = true
      exportClue({
        ...state.searchForm
      }).then(res => {
      }).catch(err => { console.log(err) })
      state.loading = false
    }
    onMounted(() => {
      getChannelList({ channelLevel: 1 })
        .then(res => {
          if (res.code === 10000) {
            state.channelList = res.data
          }
        }).catch(err => console.log(err))
      state.searchForm.createStartTime = getTimeYMD()
      state.searchForm.createEndTime = getTimeYMD()
      state.flag1 = true
      loadData()
      state.empty = JSON.parse(JSON.stringify(state.addForm))
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorSearch = JSON.stringify(state.search)
    })
    // 新增
    const onAddForm = () => {
      if (state.addForm.phoneNumber !== '' || state.addForm.wechat !== '') {
        formRef.value.validate()
          .then(() => {
            const channelArr = state.addForm.channelSubIds
            state.addForm.channelId = channelArr[0]
            state.addForm.channelSubId = channelArr[1]
            delete state.addForm.channelSubIds
            if (state.addForm.endAddress && state.addForm.endAddress.province === undefined) delete state.addForm.endAddress
            if (state.addForm.startAddress && state.addForm.startAddress.province === undefined) delete state.addForm.startAddress
            addPublic(state.addForm).then(res => {
              if (res.code === 10000) {
                state.searchAddShow = false
                message.success(res.msg)
                delete state.addForm.channelSubIds
                loadData()
                state.start = ''
                state.end = ''
                state.addForm = state.empty
                state.empty = JSON.parse(JSON.stringify(state.addForm))
                state.addForm.endAddress = {}
                state.addForm.startAddress = {}
              }
            })
          }).catch(err => { console.log(err) })
          .finally(() => { })
      } else message.error('电话和微信号必须填写其中一个')
    }
    const setStartAddress = (value, selectedOptions) => {
      state.addForm.startAddress.province = value[0]
      if (value.length > 1) {
        state.addForm.startAddress.city = value[1]
      } else {
        state.addForm.startAddress.city = null
      }
      if (value.length > 2) {
        state.addForm.startAddress.area = value[2]
      } else {
        state.addForm.startAddress.area = null
      }

    }

    const setEndAddress = (value, selectedOptions) => {
      state.addForm.endAddress.province = value[0]
      if (value.length > 1) {
        state.addForm.endAddress.city = value[1]
      } else {
        state.addForm.endAddress.city = null
      }
      if (value.length > 2) {
        state.addForm.endAddress.area = value[2]
      } else {
        state.addForm.endAddress.area = null
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const look = record => {
      state.customerId = record.customerId
      state.followShow = true
      contactCustomerIdPage(record.customerId, {
        current: state.detailPagination.current,
        size: state.detailPagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.detailPagination.current = res.data.current
          state.detailPagination.pageSize = res.data.size
          state.detailPagination.total = res.data.total
          state.listData.forEach(e => {
            if (e.followTime != null) {
              const f = e.followTime.split(' ')[0]
              const arry = f.split('-')
              e.M = arry[0] + '-' + arry[1]
              e.S = arry[2]
            }
          })
        }
      }).catch(err => {
        console.log('分页错误', err)
      }).finally(() => {
      })
    }
    const remind = record => {
      state.customerId = record.customerId
      state.reminShow = true
    }
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.search = JSON.parse(state.mirrorSearch)
      state.search.time = [getTimeYMD(), getTimeYMD()]
      state.searchForm.createStartTime = state.search.time[0]
      state.searchForm.createEndTime = state.search.time[1]
      loadData()
    }
    // 普通搜索
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const clueChange = (e, v) => {
      // state.searchForm.clueType = v.value
      if (state.searchForm.clueType !== '' && state.searchForm.clueType !== null && state.searchForm.clueType !== undefined) {
        if (state.flag4 === false) {
          state.quantity++
          state.flag4 = true
        }
      } else {
        state.quantity--
        state.flag4 = false
      }
    }
    const customerClassChange = (e, v) => {
      state.searchForm.customerClass = v
    }
    const effectiveChange = (e, v) => {
      // state.searchForm.isEffective = v
      if (state.searchForm.isEffective !== '' && state.searchForm.isEffective !== null && state.searchForm.isEffective !== undefined) {
        if (state.flag5 === false) {
          state.quantity++
          state.flag5 = true
        }
      } else {
        state.quantity--
        state.flag5 = false
      }
    }
    const setChannel = (e, v) => {
      state.searchForm.channelId = ''
      state.searchForm.channelSubId = ''
      state.searchForm.channel = [e[0]]
      state.searchForm.channelId = e[0]
      if (e.length > 1) {
        state.searchForm.channel = [e[0], e[1]]
        state.searchForm.channelSubId = e[1]
      }
      if (state.searchForm.channelId !== '' && state.searchForm.channelId !== null && state.searchForm.channelId !== undefined) {
        if (state.searchForm.channelSubId === '' || state.searchForm.channelSubId === undefined) {
          if (state.flag2 === false) {
            state.quantity++
            state.flag2 = true
          }
        }
      } else {
        state.quantity--
        state.flag2 = false
      }
    }
    const startChange = (e, v) => {
      let start = ''
      v.forEach(item => {
        start += item.label
      })
      state.searchSpecial.startAddress = start
    }
    const endChange = (e, v) => {
      let end = ''
      v.forEach(item => {
        end += item.label
      })
      state.searchSpecial.endAddress = end
    }
    const receiveChange = (e, v) => {
      state.searchForm.receiveStartTime = v[0]
      state.searchForm.receiveEndTime = v[1]
    }
    const customerType = (e, v) => {
      console.log(e, v)
    }
    const onSearchSenior = () => {
      state.searchLoading = true
      state.pagination.current = 1
      forSearchForm()
      loadData()
      state.searchSeniorShow = false
    }
    // 遍历搜索对象里面参数值有多少
    const forSearchForm = () => {
      state.searchFormTotal = Object.values(state.searchForm).length
    }
    const timeChange = (e, v) => {
      state.searchForm.createStartTime = v[0]
      state.searchForm.createEndTime = v[1]
      if (state.searchForm.createStartTime !== undefined && state.searchForm.createStartTime !== '' && state.searchForm.createEndTime !== undefined && state.searchForm.createEndTime !== '') {
        if (state.flag1 === false) {
          state.quantity++
          state.flag1 = true
        }
      } else {
        state.quantity--
        state.flag1 = false
      }
    }
    const isRepetitiveChange = (e, v) => {
      state.searchForm.isRepetitive = e
    }

    // 筛选数量叠加事件--电话输入框
    const phoneChange = (e, v) => {
      if (state.searchForm.phone !== '' && state.searchForm.phone !== null && state.searchForm.phone !== undefined) {
        if (state.flag3 === false) state.quantity++
        state.flag3 = true
      } else {
        if (state.flag3 === true && state.searchForm.phone.length === 0) {
          state.quantity--
          state.flag3 = false
        }
      }
    }

    return {
      ...toRefs(state),
      loadData,
      look,
      reset,
      remind,
      formRef,
      endChange,
      onAddForm,
      clueChange,
      customerClassChange,
      getTimeYMD,
      searchData,
      exportData,
      setChannel,
      timeChange,
      phoneChange,
      startChange,
      customerType,
      receiveChange,
      onSearchSenior,
      effectiveChange,
      setEndAddress,
      setStartAddress,
      handleTableChange,
      isRepetitiveChange
    }
  }
}
</script>

<style lang="less" scoped>@import './index.less';
@import '../tool/index.less';</style>
