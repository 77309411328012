import { postBodyRequest, exportRequest } from '@/utils/axios'

export function getPage (params) {
  return postBodyRequest('/market/crm/clue/page', params)
}

export function getPromotionCostList (params) {
  return postBodyRequest('/market/promotionCost/getPromotionCostList', params)
}

export function addCost (params) {
  return postBodyRequest('/market/promotionCost/addPromotionCost', params)
}

export function editData (params) {
  return postBodyRequest('/market/promotionCost/editPromotionCost', params)
}

export function exportClue (params) {
  return exportRequest('/market/crm/clue/export', params, '线索明细')
}
